import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.webp';
const Login = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // const loginFlag = localStorage.getItem('LoginFlag');

  // useEffect(() => {
  //   if (loginFlag) {
  //     navigate('/chat');
  //   }
  // }, [loginFlag])



  const handleLogin = () => {
    const dummyEmail = 'healthbot@nettyfy.com';
    const dummyPassword = 'He@lthbot@123';

    if (email === dummyEmail && password === dummyPassword) {
      navigate('/chat');
      // localStorage.setItem('LoginFlag', true);
    } else {
      alert('Invalid email or password');
    }
  };



  return (
    <div className='h-full flex justify-center items-center bg-bg'>
      <div>
        {/* <div className='font-semibold text-2xl mb-3'>Nettyfy Technologies</div> */}
        <img src={logo} alt='logo' className='w-[120px] mb-3' />
        <div className='bg-white shadow p-7 rounded-2xl w-[400px] flex flex-col gap-4'>
          <div className='font-semibold mb-4'>Log In to access Healthbot</div>
          <div>
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`placeholder-gray border border-lightgray rounded-lg w-full font-medium max-w-[calc(100%-0px)] py-[10px] px-4 bg-transparent appearance-none focus:outline-none text-black sm:text-[15px] text-sm`}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`placeholder-gray border border-lightgray rounded-lg w-full font-medium max-w-[calc(100%-0px)] py-[10px] px-4 bg-transparent appearance-none focus:outline-none text-black sm:text-[15px] text-sm`}
            />
          </div>
          <div className='mt-3'>
            <button
              onClick={handleLogin}
              className={`bg-primary shadow rounded-lg sm:px-4 px-2 py-[7px] h-fit m-auto text-white`}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
