import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Logo } from '../helper/Constants';
import { FaUserCircle, FaArrowUp } from "react-icons/fa";
import { FaCartShopping } from "react-icons/fa6";
import ReconnectingWebSocket from 'reconnecting-websocket';

const Chatbot = () => {
    const [messages, setMessages] = useState([]);

    console.log(messages, 'messages');

    const socket = useRef(null);

    const getCurrentTime = () => {
        const now = new Date();
        let hours = now.getHours();
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        const hoursStr = hours.toString().padStart(2, '0');
        return `${hoursStr}:${minutes} ${ampm}`;
    };

    const [newMessage, setNewMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const messageAreaRef = useRef(null);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        const messageArea = messageAreaRef.current;
        if (messageArea) {
            messageArea.scrollTop = messageArea.scrollHeight;
        }
    };

    const handleSend = (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;
        const currentTime = getCurrentTime();
        const userMessage = { text: newMessage, sender: 'user' };
        setMessages(prevMessages => [...prevMessages, {
            text: {
                response: newMessage,
                type: 'text',
                time: currentTime,
            },
            sender: 'user'
        }]);

        setNewMessage('');

        setIsTyping(true);
        socket.current.send(JSON.stringify({ "text": newMessage }));

    };

    useEffect(() => {
        socket.current = new ReconnectingWebSocket('wss://ecombotapi.nettyfy.com/ws/healthbot_db_call');
        const currentTime = getCurrentTime();

        // Add event listeners
        socket.current.addEventListener('open', (e) => {
            console.log('Connected to the WebSocket server');

        });
        socket.current.addEventListener('message', (event) => {
            setMessages(prevMessages => [...prevMessages, { text: JSON.parse(event.data), sender: 'Shopbot', time: currentTime }]);
            setIsTyping(false);
        });

        socket.current.addEventListener('error', (e) => {
            console.log('WebSocket connection Error');
            setMessages(prevMessages => [...prevMessages, { text: "Apologies, we're currently undergoing maintenance. Please try again later. Thank you for your patience.", sender: 'Shopbot' }]);
            setIsTyping(false);
        });


        return () => {
            socket.current.close();
            console.log('WebSocket connection closed');
        };
    }, []);

    const handleSendText = (text) => {
        const currentTime = getCurrentTime();
        setMessages(prevMessages => [...prevMessages, {
            text: {
                response: text,
                type: 'text',
                time: currentTime
            },
            sender: 'user'
        }]);

        setIsTyping(true);
        socket.current.send(JSON.stringify({ "text": text }));
    };


    const renderMessages = () => messages?.map((message, index) => {
        const { product } = message?.text;
        const { suggestion } = message?.text;
        return (

            <div key={index} className={`message ${message.sender}`}>
                <div className={`flex-col items-start flex sm:gap-3 gap-1`}>
                    <div className={`${message.sender === 'user' ? 'flex-row justify-end' : 'flex-row items-start'} flex w_web sm:gap-2 gap-1`}>
                        {message.sender === 'user' ? '' :
                            <div className='sm:w-8 sm:h-8 w-7 h-7 rounded-lg bg-lightgray p-[5px] shadow'>
                                <img src={Logo} className='w-[60px]' alt='bot_logo' />
                            </div>
                        }
                        <div className={`${message.sender === 'user' ? '!rounded-tr-none bg-primary text-white' : '!rounded-tl-none bg-lightgray text-black'} rounded-lg  sm:px-5 sm:py-3 p-2 font-medium max-w-[80%] sm:text-[15px] text-sm relative text_a_tag`}>
                            <div dangerouslySetInnerHTML={{ __html: message?.text?.response }}></div>
                            <div className={`${message.sender === 'user' ? 'left-[5px]' : 'right-[5px]'} text-[10px] absolute bottom-[-18px] text-gray`}>{message.sender === 'user' ? message?.text?.time : message?.time}</div>
                        </div>
                    </div>
                    {suggestion &&
                        <div className='flex flex-row gap-1 ml-10 flex-wrap mt-2'>
                            {suggestion.map((data, i) => {
                                return (
                                    <div key={i} className='bg-bgSug hover:bg-primary hover:text-white px-4 py-2 cursor-pointer rounded-2xl max-w-[85%]'
                                        onClick={() => handleSendText(data?.question)}
                                    >
                                        {data?.question}
                                    </div>
                                )
                            })}
                        </div>

                    }
                    {/* {
                        product &&
                        <div className='w-
                        [650px]'>
                            <Slider
                                dots={false}
                                autoplay={false}
                                infinite={false}
                                speed={500}
                                slidesToShow={3}
                                slidesToScroll={1}
                                prevArrow={<MdKeyboardArrowLeft className='text-black text-2xl font-bold' />}
                                nextArrow={<MdKeyboardArrowRight className='text-black text-2xl font-bold' />}
                                responsive={[
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                            slidesToShow: 2,
                                        }
                                    },
                                    {
                                        breakpoint: 768,
                                        settings: {
                                            slidesToShow: 2,
                                        }
                                    },
                                    {
                                        breakpoint: 500,
                                        settings: {
                                            slidesToShow: 1,
                                        }
                                    }
                                ]}
                            >
                                {product?.map((data, i) => {
                                    return (
                                        <div className='sm:px-1 px-1 h-full' key={i}>
                                            <div className='h-full'>
                                                <div className='border border-[#dedede] rounded-lg w-full flex flex-col justify-between'>
                                                    <img src={data?.img} className='p-1 w-[150px] h-[150px] object-contain mx-auto mt-3' />
                                                    <div className='flex flex-col justify-between'>
                                                        <div className='p-3'>
                                                            <div className='font-medium text-lg overflow-hidden whitespace-nowrap overflow-ellipsis'>{data?.product_name}</div>
                                                            <div className='flex gap-2'>
                                                                <div>Price :</div>
                                                                <div className='font-semibold'>{data?.price}</div>
                                                            </div>
                                                        </div>
                                                        <div className='border-t border-t-[#dedede] p-2 text-center text-sm'>
                                                            <Link to={data?.more_details} target='_blank'>Know More</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </Slider>
                        </div>
                    } */}
                </div>
            </div>
        )
    });

    return (
        <div className='bg-bg m-auto h-full w-full'>
            <div className='h-full m-auto'>
                <div className='h-full rounded-2xl shadow-xl flex'>
                    <div className='w-full'>
                        <div className='h_web flex flex-col justify-between items-start p-10 rounded-t-2xl'>
                            <div className='flex flex-col gap-5'>
                                <div className='sm:w-14 sm:h-14 w-10 h-10 rounded-lg bg-white p-[7px] shadow'>
                                    <img src={Logo} className='w-[60px]' alt='bot_logo' />
                                </div>
                                <div>
                                    <div className='sm:text-xl text-lg text-gray font-semibold'>
                                        Welcome to HealthMate's Assistant,  <br /> here to guide you towards better health with ease! </div>
                                </div>
                            </div>
                            <div>
                                <div className='text-gray text-lg'>Powered ⚡ by <Link to='https://nettyfy.com/' target='_blank' className='text-primary font-semibold'>Nettyfy Technologies</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full h-web rounded-2xl bg-white shadow m-4'>
                        <div className='h-full'>
                            <div className='h-[calc(100%-65px)]'>
                                <div className='h-full'>
                                    <div className='overflow-x-hidden flex flex-col sm:gap-5 gap-3 message_area h-full sm:px-4 sm:py-6 p-3' id="messageArea" ref={messageAreaRef}>
                                        {renderMessages()}
                                        {isTyping === true &&
                                            <div className={`message`}>
                                                <div className='flex items-start sm:gap-2 gap-1'>
                                                    <div className='sm:w-8 sm:h-8 w-7 h-7 rounded-lg bg-lightgray p-[5px] shadow'>
                                                        <img src={Logo} className='w-[60px]' alt='bot_logo' />
                                                    </div>
                                                    {/* <FaCartShopping className='sm:w-8 sm:h-8 w-7 h-7 rounded text-primary bg-lightgray p-[6px]' /> */}
                                                    <div className='!rounded-tl-none bg-lightgray rounded-lg text-primary sm:px-4 sm:py-3 p-2 font-medium max-w-[80%] sm:text-[15px] text-sm'>
                                                        <div className='w-12 flex items-center justify-center h-5'>
                                                            <div className="dot-pulse"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='sm:pb-4 sm:px-4 px-3 pb-3 flex'>
                                <div className={`${isTyping ? 'border-gray ' : 'border-primary '} flex w-full rounded-[10px] bg-lightgray shadow`}>
                                    <form className='flex items-center w-full ' onSubmit={handleSend}>
                                        <input placeholder="Type a message..." className={`${isTyping ? 'placeholder-gray' : 'placeholder-black'}  rounded-lg w-full font-medium max-w-[calc(100%-56px)] sm:p-[14px] p-[10px] bg-transparent appearance-none focus:outline-none text-black sm:text-[15px] text-sm`}
                                            value={newMessage}
                                            onChange={(e) => setNewMessage(e.target.value)}
                                        // disabled={isTyping}
                                        />
                                        <button type="submit" disabled={newMessage.trim() === '' ? true : false} className={`${newMessage.trim() === '' ? 'bg-bg' : 'bg-primary shadow'} rounded-lg sm:px-[10px] sm:py-2 px-2 py-[7px] h-fit m-auto sm:mr-2 mr-[6px]`}>
                                            <FaArrowUp className='sm:text-[16px] text-sm text-white' />
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chatbot