import './App.css';
import Chatbot from './components/Chatbot';
import Login from './components/Login';
import PageNotFound from './helper/PageNotFound';
import ScrollToTop from './helper/ScrollToTop';
import { Route, Routes, useLocation } from "react-router-dom";

function App() {
  return (
    <ScrollToTop>
      <div className=''>
        <div className='h-screen chat_area'>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/chat" element={<Chatbot />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </div>
    </ScrollToTop>
  );
}

export default App;
